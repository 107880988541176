<template>
  <div id="app">
    <div id="nav">
    </div>
    <router-view/>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import authentication from '@/mixins/authentication.js'

export default {
    mixins: [authentication],
    name: 'App',
    methods: {
        async logout() {
            try {
                await Auth.signOut();
            } catch (error) {
                alert(error.message);
            }
        },
    },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
