<template>
  <div class="container">

  </div>
</template>

<script>
import { API } from 'aws-amplify'

export default {
  name: "learning-sso",
  async beforeCreate () {
    API
      .get("SSOApi", "/api/sso/360learning")
      .then(response => {
        window.location.href = response['redirect'];
      })
      .catch(error => {
        console.log(error.response);
    });
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {},
};
</script>
