import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { Auth, Logger } from 'aws-amplify'
import { Hub } from '@aws-amplify/core';

export default {
  async beforeCreate () {
    const logger = new Logger('authentication-mixin')
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'customOAuthState': {
            const original_url = decodeURIComponent(data);
            window.location.href = original_url;
            break;
        }
      }
    })
    try {
      this.user = await Auth.currentAuthenticatedUser()
      this.user_attributes = await Auth.userAttributes(this.user)
      this.authState = 'signedin'
      this.groups = this.user.signInUserSession.accessToken.payload['cognito:groups']
      this.signedIn = true
      this.userCreds = await Auth.currentSession()
      logger.info('signed in user: ' + JSON.stringify(this.user))
    } catch (err) {
      logger.info(err)
      this.message = err
      this.signedIn = false
      const from = this.$router.history.current.path;
      const urlSearchParams = new URLSearchParams(window.location.search)
      const rawError = urlSearchParams.get('error_description')
      if (rawError && /already.found.an.entry.for.username.google/gi.test(rawError)) {
        Auth.federatedSignIn({provider: 'Google'})
        return
      }
      Auth.federatedSignIn({customState: from})
    }
  },
  data () {
    return {
      message: undefined,
      user: undefined,
      authState: undefined,
      user_attributes: undefined,
      userCreds: undefined
    }
  },
  methods: {
    signOut: async () => await Auth.signOut()
  },
  beforeDestroy () {
    return onAuthUIStateChange
  }
}
